import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useState
} from "react"
import fetch from "isomorphic-fetch"
import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    InMemoryCache
} from "@apollo/client"
import reducer, { initialState } from "./api/reducer"
import { setContext } from "apollo-link-context"
import { createUploadLink } from "apollo-upload-client"
import { onError } from "apollo-link-error"
import {
    CREATE_AUTHENTICATION,
    EXCHANGE_AUTHENTICATION,
    VERIFY_AUTHENTICATION
} from "../schemas/authentication"
import { API_CREATE_AUTHENTICATION, API_LOGGED_IN } from "./api/actions"

const ACCESS_TOKEN_KEY = "accessToken"

const ApiContext = React.createContext()

const useApi = () => useContext(ApiContext)

const ApiProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState || {})
    const [accessToken, setAccessToken] = useState(null)

    const uploadLink = createUploadLink({
        uri: process.env.GATSBY_API_URL
    })

    const authLink = setContext((_, { headers }) => {
        return {
            headers:
                (headers && headers.authorization) || !accessToken
                    ? headers
                    : { ...headers, authorization: `Bearer ${accessToken}` }
        }
    })

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.map(({ message, locations, path }) =>
                console.error(message)
            )
        }

        if (networkError) {
            console.error(networkError.message)
        }
    })

    const client = useMemo(() => {
        return new ApolloClient({
            cache: new InMemoryCache(),
            fetch,
            link: ApolloLink.from([errorLink, authLink, uploadLink])
        })
    }, [errorLink, authLink, uploadLink])

    const getQRCode = useCallback(async () => {
        if (state.qrCode) {
            return state.qrCode
        } else {
            const {
                data: { createAuthentication }
            } = await client.mutate({
                mutation: CREATE_AUTHENTICATION,
                variables: { action: "register" }
            })

            if (createAuthentication && createAuthentication.code) {
                dispatch({
                    type: API_CREATE_AUTHENTICATION,
                    createAuthentication
                })

                return state.qrCode
            }
        }
    }, [client, state.qrCode])

    const handleLogin = (token) => {
        setAccessToken(token)
        dispatch({ type: API_LOGGED_IN })
    }

    const setOrRemoveItem = (key, value) => {
        if (value) {
            localStorage.setItem(key, value)
        } else {
            localStorage.removeItem(key)
        }
    }

    const verifyCode = useCallback(async () => {
        if (state.code) {
            const {
                data: { verifyAuthentication }
            } = await client.query({
                query: VERIFY_AUTHENTICATION,
                fetchPolicy: "no-cache",
                variables: {
                    code: state.code
                }
            })

            if (verifyAuthentication && verifyAuthentication.verified) {
                try {
                    const {
                        data: { exchangeAuthentication }
                    } = await client.mutate({
                        mutation: EXCHANGE_AUTHENTICATION,
                        variables: {
                            code: state.code
                        }
                    })

                    if (exchangeAuthentication && exchangeAuthentication.token) {
                        handleLogin(exchangeAuthentication.token)
                        return { verified: true }
                    }
                } catch (error) {
                    return { verified: false, error }
                }
            }
        }

        return { verified: false }
    }, [client, state.code])

    useEffect(() => {
        // Loads the token from local storage
        handleLogin(localStorage.getItem(ACCESS_TOKEN_KEY))
    }, [])

    useEffect(() => {
        // Stores the token to local storage
        setOrRemoveItem(ACCESS_TOKEN_KEY, accessToken)
    }, [accessToken])

    const value = useMemo(() => {
        return { state, getQRCode, verifyCode }
    }, [state, getQRCode, verifyCode])

    return (
        <ApiContext.Provider value={value}>
            <ApolloProvider client={client}>{props.children}</ApolloProvider>
        </ApiContext.Provider>
    )
}

export { ApiContext, useApi }
export default ApiProvider
