/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useSiteMetadata } from "../hooks/useSiteMetadata"

function SearchEngineOptimization({
    description,
    lang,
    meta,
    keywords,
    title
}) {
    const {
        title: defaultTitle,
        description: defaultDescription,
        image,
        twitterUsername
    } = useSiteMetadata()

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${process.env.GATSBY_WEB_URL}${image}`,
        url: `${process.env.GATSBY_WEB_URL}`,
        twitterUsername
    }

    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={title}
            titleTemplate={`%s | ${defaultTitle}`}
            meta={[
                {
                    name: `description`,
                    content: seo.description
                },
                {
                    name: `image`,
                    content: seo.image
                },
                {
                    property: `og:type`,
                    content: `website`
                },
                {
                    property: `og:title`,
                    content: seo.title
                },
                {
                    property: `og:description`,
                    content: seo.description
                },
                {
                    name: `og:url`,
                    content: seo.url
                },
                {
                    name: `og:image`,
                    content: seo.image
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`
                },
                {
                    name: `twitter:creator`,
                    content: seo.twitterUsername
                },
                {
                    name: `twitter:title`,
                    content: seo.title
                },
                {
                    name: `twitter:description`,
                    content: seo.description
                },
                {
                    name: `twitter:url`,
                    content: seo.url
                },
                {
                    name: `twitter:image`,
                    content: seo.image
                }
            ]
                .concat(
                    keywords.length > 0
                        ? {
                              name: `keywords`,
                              content: keywords.join(`, `)
                          }
                        : []
                )
                .concat(meta)}
        />
    )
}

SearchEngineOptimization.defaultProps = {
    lang: `en`,
    meta: [],
    keywords: [],
    description: ``
}

SearchEngineOptimization.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    keywords: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string.isRequired
}

export default SearchEngineOptimization
